import { useQuery } from '@apollo/client';
import Client from '../../client/Client';
import { useProjectID } from './useProjectID';
import { useClientsAll } from './useClientsAll';
import { useStore } from 'store/storeUtils';
import { useClientsUpdatedAfter } from './useProjectClients';

export const useClientsUpdate = () => {
  const data = useClientsAll();
  const project_id = useProjectID();
  const store = useStore();
  const updatedAfter = useClientsUpdatedAfter();

  const { refetch } = useQuery(Client.GET_ALL_CLIENTS_UPDATE, {
    fetchPolicy: 'no-cache',
    skip: true,
  });

  const onClientListUpdate = async () => {
    if (project_id && updatedAfter) {
      const response = await refetch({ project_id, updatedAfter });
      const { dates } = response.data.getAllUpdatedClientsFollowUp;
      const clientsUpdated = response.data.getAllUpdatedClientsFollowUp.elements || [];
      const prevClients = data.clients.data.getAllClientsFollowUp;

      const prevClientsMap = new Map(prevClients.map((client) => [client.id, client]));

      let newClients = [];
      let updatedClients = [...prevClients];

      clientsUpdated.forEach((updatedClient) => {
        if (prevClientsMap.has(updatedClient.id)) {
          const index = updatedClients.findIndex((client) => client.id === updatedClient.id);
          if (index !== -1) updatedClients[index] = updatedClient;
        } else newClients.push(updatedClient);
      });

      const finalClientsList = [...newClients, ...updatedClients];

      const date = dates && dates.to ? dates.to : '';

      store.setProjectClients(project_id, finalClientsList, date);
      store.setClients(finalClientsList);

      return {
        data: {
          getAllClientsFollowUp: finalClientsList,
        },
        loading: finalClientsList.length === 0,
        networkStatus: 7,
      };
    }
  };

  return { onClientListUpdate };
};
